import React from "react";
import Helmet from "react-helmet";
import window from "global";
import { Link } from 'gatsby';

import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

import Email from "@material-ui/icons/Email";
import LockOutlined from "@material-ui/icons/LockOutlined";

import { FaFacebook, FaTwitter, FaGooglePlusG } from 'react-icons/fa';

import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import bookImage from "../../assets/img/dad.jpg";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

const bookmerlinImage = window.innerWidth >= 650 ? bookImage : bookImage;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
           color="#009688"
           brand="BookMerlin"
           rightLinks={<HeaderLinks />}
           fixed
           changeColorOnScroll={{
             height: 50,
             color: "#009688"
           }}
           {...rest}
        />
        <div>
          <div className={classes.img} style={{backgroundImage: `url(${bookmerlinImage})`}}>
          <div className={classes.container}>
            <GridContainer justify="center" >
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader className={classes.cardHeader}>
                      <h4>Login</h4>
                      <div className={classes.socialLine}>
                        <Button
                          justIcon
                          href="#pablo"
                          target="_blank"
                          color="transparent"
                          onClick={e => e.preventDefault()}
                        >
                          <FaGooglePlusG/>
                        </Button>
                        <Button
                          justIcon
                          href="#pablo"
                          target="_blank"
                          color="transparent"
                          onClick={e => e.preventDefault()}
                        >
                          <FaFacebook/>
                        </Button>
                        <Button
                          justIcon
                          href="#pablo"
                          target="_blank"
                          color="transparent"
                          onClick={e => e.preventDefault()}
                        >
                          <FaTwitter/>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className={classes.cardBody}>

                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />

                      <CustomInput
                        labelText="Password"
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutlined/>
                            </InputAdornment>
                          )
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <p className={classes.text}>
                          Forgot password?
                            <Link className={classes.forgotLink}>Click Here</Link>
                      </p>
                      <Link className={classes.link} >
                        Login
                      </Link>
                      <p className={classes.text}>
                          Not a Member? Don't miss out!
                      </p>
                      <Link to='/register' className={classes.link} >
                          Register Here!
                      </Link>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
            </div>
            </div>
            </div>
          <Footer  />
        </div>
    );
  }
}

export default withStyles(loginPageStyle)(LoginPage);
